<template>
    <div class="flex flex-col">
        <warning
            icon="regular/content-pencil-write"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />
        <template v-else>
            <portal to="content-header-left">
                <activix-reload :loading="isLoading" @click="fetchTemplates" />
            </portal>

            <div class="box" :class="{ loading: isLoading }">
                <div class="box-header | h-auto p-6 flex justify-between">

                    <el-dropdown
                        trigger="click"
                        class="flex items-center"
                        placement="bottom-start"
                        @click.native.stop
                        v-if="automationNotificationEnabled"
                    >
                        <button class="btn btn-primary">
                            <icon name="bold/add" class="mr-2 text-sm" />
                            {{ $t('templates.create') }}
                        </button>

                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <router-link :to="{ name: 'templates.store', params: { type: 'email'}}" class="link-gray">
                                    <icon name="regular/send-email" class="mr-2 mb-0.5 text-sm" />
                                    {{ $t('templates.email') }}
                                </router-link>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <router-link :to="{ name: 'templates.store', params: { type: 'notification'}}" class="link-gray">
                                    <icon name="regular/alarm-bell" class="mr-2 mb-0.5 text-sm" />
                                    {{ $t('templates.notification') }}
                                </router-link>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                    <router-link :to="{ name: 'templates.store', params: { type: 'email'}}" class="btn btn-primary" v-else>
                        <icon name="bold/add" class="mr-2 text-sm" />
                        {{ $t('templates.create') }}
                    </router-link>
                    <input
                        class="form-control w-auto"
                        type="text"
                        :placeholder="$t('search.search')"
                        v-model="tableState.searchToken"
                    />
                </div>
                <div class="box-body | p-0">
                    <el-table
                        class="w-full border-t-2 border-b"
                        :border="true"
                        :data="paginatedTemplates"
                        :default-sort="{ prop: 'title', order: 'ascending' }"
                        :stripe="true"
                        @sort-change="updateTableSorting"
                    >
                        <el-table-column
                            prop="title"
                            header-align="center"
                            min-width="400"
                            :label="$t('templates.name')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <router-link
                                    class="edit-template-link"
                                    :to="{ name: 'templates.update', params: { id: scope.row.id } }"
                                >
                                    {{ handleTemplateTitle(scope.row) }}
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="type"
                            align="center"
                            width="150"
                            :label="$t('templates.type')"
                            :sortable="true"
                            :formatter="handleTemplateType"
                            v-if="automationNotificationEnabled"
                        />
                        <el-table-column
                            prop="division_id"
                            align="center"
                            width="100"
                            :label="$t('templates.category')"
                            :sortable="true"
                            :formatter="handleDivisionName"
                        />
                        <el-table-column width="120" align="center" :sortable="false">
                            <template slot-scope="scope">
                                <router-link
                                    class="link-grey"
                                    :to="{ name: 'templates.update', params: { id: scope.row.id } }"
                                >
                                    <activix-tooltip :content="$t('templates.edit')">
                                        <icon name="regular/pencil-1" />
                                    </activix-tooltip>
                                </router-link>
                                <a class="link-grey ml-3" @click="onDuplicate(scope.row.id)">
                                    <activix-tooltip :content="$t('templates.duplicate')">
                                        <icon name="regular/common-file-double-1" />
                                    </activix-tooltip>
                                </a>
                                <a
                                    class="link-grey ml-3"
                                    :class="{
                                        'hover:text-yellow-500': !scope.row.is_favorite,
                                        'text-yellow-500 hover:text-grey-500': scope.row.is_favorite,
                                    }"
                                    @click="toggleFavorite(scope.row)"
                                >
                                    <activix-tooltip :content="$t('general.favorite')">
                                        <icon name="regular/rating-star" :fill="scope.row.is_favorite" />
                                    </activix-tooltip>
                                </a>
                                <a
                                    class="link-grey ml-3 | hover:text-red-500"
                                    @click="onDelete(scope.row.id)"
                                    v-if="authUser.isAdmin() || authUser.isDirector() || authUser.isBdcDirector()"
                                >
                                    <activix-tooltip :content="$t('general.delete')">
                                        <icon :name="$icons.trash" />
                                    </activix-tooltip>
                                </a>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        class="p-6"
                        :layout="paginationLayout"
                        :background="true"
                        :current-page.sync="tableState.currentPage"
                        :page-size.sync="tableState.perPage"
                        :page-sizes="tableState.pageOptions"
                        :pager-count="5"
                        :total="filteredSortedTemplates.length"
                        @size-change="updateTablePerPage"
                        v-if="templates.length > 0"
                    />
                </div>
            </div>
        </template>

        <activix-confirm-modal
            type="warning"
            :content="$t('templates.deleteModal')"
            :opened.sync="deleteModal.opened"
            @approve="deleteTemplate"
            @closed="deleteModal.templateId = null"
        />
        <activix-confirm-modal
            type="warning"
            :content="$t('templates.unFavoriteModal')"
            :opened.sync="unFavoriteModal.opened"
            @approve="removeTemplateFromFavorite"
            @closed="unFavoriteModal.templateId = null"
        />
    </div>
</template>

<script>
    import { orderBy } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import TemplateType from '@/entities/TemplateType';
    import TrackView from '../../mixins/TrackView.js';
    import DataTableMixin from '../../mixins/DataTable.js';
    import Warning from '../../components/Warning.vue';
    import Division from '../../entities/Division.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'TemplatesList',

        components: {
            Warning,
        },

        mixins: [TrackView, DataTableMixin],

        data() {
            return {
                templates: [],
                deleteModal: {
                    opened: false,
                    templateId: null,
                },
                unFavoriteModal: {
                    opened: false,
                    templateId: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            automationNotificationEnabled() {
                return this.$feature.isEnabled('automation-notification');
            },

            isLoading() {
                return this.$wait.is(['fetching.contextAccount', 'fetching.templates']);
            },

            formattedTemplates() {
                return this.templates.map(row => {
                    return {
                        ...row,
                        title: this.handleTemplateTitle(row),
                        raw: { title: this.handleTemplateTitle(row) },
                        division_name: this.handleDivisionName(row),
                    };
                });
            },

            filteredSortedTemplates() {
                return orderBy(
                    this.searchData(this.tableState.searchToken, this.formattedTemplates),
                    [this.tableState.sorting.column],
                    [this.tableState.sorting.order],
                );
            },

            paginatedTemplates() {
                return this.filteredSortedTemplates.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },

            validAccount() {
                return !!this.contextAccount.id;
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                handler() {
                    this.fetchTemplates();
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, ['reloadContextAccount']),

            async fetchTemplates() {
                if (!this.validAccount) {
                    return;
                }

                this.$wait.start('fetching.templates');

                try {
                    const response = await this.$axios.get('v1/templates', {
                        params: {
                            account: this.contextAccount.id,
                        },
                    });

                    this.templates = response.data.data;

                    this.$wait.end('fetching.templates');
                } catch (error) {
                    this.$wait.end('fetching.templates');
                    this.$notify.error(this.$t('templates.alerts.index.error'));

                    throw error;
                }
            },

            async onDuplicate(id) {
                try {
                    await this.$axios.post(`v1/templates/${id}/duplicate`);

                    this.fetchTemplates();

                    this.$notify.success(this.$t('templates.alerts.duplicate.success'));
                } catch (error) {
                    this.$notify.error(this.$t('templates.alerts.duplicate.error'));

                    throw error;
                }
            },

            onDelete(id) {
                this.deleteModal.opened = true;
                this.deleteModal.templateId = id;
            },

            toggleFavorite(template) {
                if (template.is_favorite) {
                    this.unFavoriteModal.opened = true;
                    this.unFavoriteModal.templateId = template.id;
                } else {
                    this.addTemplateToFavorite(template.id);
                }
            },

            async deleteTemplate() {
                const id = this.deleteModal.templateId;

                if (!id) {
                    return;
                }

                try {
                    await this.$axios.delete(`v1/templates/${id}`);

                    this.fetchTemplates();

                    this.$notify.success(this.$t('templates.alerts.destroy.success'));
                } catch (error) {
                    this.$notify.error(this.$t('templates.alerts.destroy.error'));

                    throw error;
                }
            },

            async addTemplateToFavorite(id) {
                if (!id) {
                    return;
                }

                try {
                    await this.$axios.post(`v1/templates/${id}/favorite`);

                    this.reloadContextAccount();

                    this.fetchTemplates();

                    this.$notify.success(this.$t('templates.alerts.favorite.success'));
                } catch (error) {
                    this.$notify.error(this.$t('templates.alerts.favorite.error'));
                    throw error;
                }
            },

            async removeTemplateFromFavorite() {
                const id = this.unFavoriteModal.templateId;

                if (!id) {
                    return;
                }

                try {
                    await this.$axios.delete(`v1/templates/${id}/favorite`);

                    this.reloadContextAccount();

                    this.fetchTemplates();

                    this.$notify.success(this.$t('templates.alerts.unFavorite.success'));
                } catch (error) {
                    this.$notify.error(this.$t('templates.alerts.unFavorite.error'));
                    throw error;
                }
            },

            handleTemplateTitle(row) {
                if (row.title) {
                    return row.title;
                }

                let title = '';

                row.response_template_translations.forEach(translation => {
                    if (
                        translation.title &&
                        !empty(`${translation.title}`.trim()) &&
                        (translation.locale == this.$i18n.locale || empty(title.trim()))
                    ) {
                        title = translation.title;
                    }
                });

                return title || this.$t('automations.table.row.noName');
            },

            getRowType(row) {
                return row.type ? row.type : TemplateType.EMAIL;
            },

            handleDivisionName(row) {
                return Division.getTranslation(row.division_id);
            },

            handleTemplateType(row) {
                return TemplateType.getTranslation(this.getRowType(row));
            },
        },
    };
</script>
